<template>
  <div>
  <v-breadcrumbs :items="breadcrumbsItems">
    <template v-slot:divider>
      <v-icon class="secondary--text text--lighten-2"
      >fas fa-caret-right</v-icon
      >
    </template>
  </v-breadcrumbs>

  <v-card class="elevation-5">
    <v-toolbar flat class="blue-grey lighten-4">
      <v-toolbar-title>Contratti</v-toolbar-title>
    </v-toolbar>

    <v-list>
      <v-list-item v-for="contract in items" :key="contract.ContractTypeId">
        <v-list-item-title>{{ contract.Title}}</v-list-item-title>
        <v-list-item-action>
          <v-btn icon @click="loadEditView(contract.ContractTypeId)"><v-icon>fas fa-edit</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-dialog v-model="showEditDialog"
              fullscreen
              @keydown.esc="$emit('input', false)"
              no-click-animation
              scrollable
              persistent>
      <v-card>
        <v-card-text>
        <v-toolbar color="nyGreen">
          <v-spacer></v-spacer>
          <v-toolbar-items>
      <v-btn @click="showEditDialog = false">{{ $t("common.close") }}</v-btn>
            <v-btn @click="UpdateContractText" :loading="formEditor.SendingData">{{ $t("common.save") }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <VueEditor v-model="formEditor.Text" id="editor" useCustomImageHandler
                ></VueEditor>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>

  </div>
</template>

<script>
import CrudClient from "@/services/CrudClient";
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
	name: "Contracts",
	components: { VueEditor },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "System Settings",
					disabled: false,
					exact: true,
					to: "/SystemSettings",
				},
				{
					text: "Contratti",
					disabled: true,
					exact: true,
					to: "/SystemSettings/Contracts",
				}
			],
			LoadingData: false,
			items: [],
			itemsPaging: [], // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 10,
			options: {
				page: 1,
				itemsPerPage: 20,
				totalItems: 0,
			},
			showEditDialog: false,
			currentText: null,
			selectedContractTypeId: null,
			formEditor: {
				SendingData: false,
				Text: null
			},

		}
	},
	created() {
		this.ContractsService = new CrudClient("Contracts");
	},
	async mounted() {
		await this.LoadContractsList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async loadEditView(contractTypeId) {
			const res = await this.ContractsService.Get(contractTypeId);

			this.selectedContractTypeId = contractTypeId;
			this.formEditor.Text = res.Text;
			this.showEditDialog = true;
		},

		async UpdateContractText() {
			this.formEditor.SendingData = true;
			try {
				await this.ContractsService.Patch(this.selectedContractTypeId, {
					Text: this.formEditor.Text
				});
				this.snackSuccess({ Text: this.$t("common.success") });
				this.showEditDialog = false;
				await this.LoadContractsList();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formEditor.SendingData = false;
			}
		},

		async LoadContractsList() {
			this.LoadingData = true;
			try {
				const res = await this.ContractsService.GetPaged({
					limit: this.options.itemsPerPage,
					skip: (this.options.page - 1) * this.options.itemsPerPage,
					fields:
            "ContractTypeId, Title",
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		}
	}
}
</script>
<i18n>
{"it": {
  "pageTitle": "Contratti",
  "systemSettings": "System Settings"
}}
</i18n>
